"use client";
// Imports
import React, { useState, useEffect } from "react";
import classnames from "classnames";

// CSS
import "./css/Header.css";

// Components
import Logo from "../assets/images/moxxi/logo/Moxxi_PilatesChair_Logo.svg";
import Button from "./Button";

const ns = "header";

// ======== Component ========
const Header = () => {
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (window.scrollY) setScrolled(true);

        const eventHandler = () => {
            if (window.scrollY) return setScrolled(() => true);
            if (!window.scrollY) return setScrolled(() => false);
        };

        window.addEventListener("scroll", eventHandler, {passive: true});

        return () => window.removeEventListener("scroll", eventHandler, {passive: true});
    }, []);

    const rootClassnames = classnames({
        [ns]: true,
        [`${ns}--scrolled`]: isScrolled,
    });

    return (
        <header className={rootClassnames}>
            <div className={`container`}>
                <div className={`${ns}__wrapper`}>
                    <Logo className={`${ns}__logo`} />
                    <Button className={`${ns}__button`} />
                </div>
            </div>
        </header>
    );
};

export default Header;
