import React from "react";

import "./css/Button.css";

import classNames from "classnames";

import Amazon from "../assets/images/moxxi/icons/amazon.svg";

const ns = "button";

function Button({ className }) {
    const rootClassnames = classNames({
        [ns]: true,
        [className]: !!className,
    });

    return (
        <a
            className={rootClassnames}
            href="https://a.co/d/3BU1lJH"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="purchase link"
        >
            <span>Purchase</span>
            <i />
            <Amazon />
        </a>
    );
}

export default Button;
