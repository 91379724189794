// Imports
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// ======== Component ========
const HeadTags = (props) => {
    return (
        // Supports all valid head tags: title, base, meta, link, script, noscript, and style tags.
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
            <meta name="description" content={props.desc} />
            <link rel="icon" href="favicon.ico" />
        </Helmet>
    );
};

// Prop validation
HeadTags.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
};

export default HeadTags;
