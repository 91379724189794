// Imports
import React from "react";

// Global CSS
import "../assets/css/globalReset.css";
import "../assets/css/globalFonts.css";
import "../assets/css/globalStyles.css";
import "swiper/css";
import "swiper/css/navigation";

// CSS
import "./css/Layout.css";

// Components
import Header from "./Header";
import Footer from "./Footer";
import Seo from "./SEO";

// ======== Component ========
const Layout = ({ children }) => {
    return (
        <main className={`layout`}>
            <Seo
                title={"Moxxi"}
                desc={"Moxxi main page"}
            />
            <Header />
            <div className={`layoutMain`}>{children}</div>
            <Footer />
        </main>
    );
};

export default Layout;
