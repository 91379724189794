// Imports
import React from "react";

// CSS
import "./css/Footer.css";

const ns = "footer";

// ======== Component ========
const Footer = () => {
    return (
        <footer className={ns}>
            <div className={`container`}>
                <div className={`${ns}__top`}>
                    <a
                        href="https://www.pilates.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms & Conditions
                    </a>
                    <a
                        href="https://www.pilates.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy
                    </a>
                    <a
                        href="https://www.pilates.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Legal Notice
                    </a>
                    <a
                        href="https://www.pilates.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Accessibility
                    </a>
                </div>
                <div className={`${ns}__bottom`}>
                    Please <a href="mailto:info@pilates.com">email</a> us or call us 1-916-388-2838 with questions or
                    comments.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
